import CandidatInfo from './CandidatInfo'
import CandidatOtherInfo from './CandidatOtherInfo'
import CandidatDispoTable from './CandidatDispoTable'
import axiosInstance from '../../axios/axiosInstance'
import CandidatDocuments from './CandidatDocuments'
import AxiosInstance from '../../axios/axiosInstance'
import { useEffect, useState } from 'react'
import Entretien from './Entretien/Entretien'
import CandidateComment from './CandidateComment'
import CandidatContract from './CandidatContract/CandidatContract'

export default function CandidatModalDetails({
  user,
  setCandidates,
  isOpen,
  setIsOpen,
  setUser,
  candidates,
}) {
  const [section, setSection] = useState('general')
  const [entretien, setEntretien] = useState([])

  const getEntretien = () => {
    AxiosInstance.get(`entretien/${user._id}`)
      .then((res) => setEntretien(res.data))
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getEntretien()
  })

  const deleteUser = () => {
    axiosInstance
      .delete(`tempworker-candidate/${user._id}`)
      .then(() => console.log('deleted'))
      .catch((e) => console.log(e))
  }

  return (
    <div>
      <div className="flex items-center justify-between space-x-2">
        <p
          onClick={() => setSection('general')}
          className={`${
            section === 'general'
              ? 'font-semibold text-red-500 border-l rounded-tl-lg rounded-tr-lg text-center border-t border-r w-1/4 bg-red-50'
              : 'text-gray-500'
          }   hover:cursor-pointer uppercase px-6 pt-4`}
        >
          General{' '}
        </p>

        <p
          onClick={() => setSection('document')}
          className={`${
            section === 'document'
              ? 'font-semibold text-orange-500 border-l rounded-tl-lg rounded-tr-lg text-center border-t border-r w-1/4 bg-orange-50'
              : 'text-gray-500'
          }   hover:cursor-pointer uppercase px-6 pt-4`}
        >
          Documents{' '}
        </p>

        <p
          onClick={() => setSection('dispo')}
          className={`${
            section === 'dispo'
              ? 'font-semibold text-green-500 border-l rounded-tl-lg rounded-tr-lg text-center border-t border-r w-1/4 bg-green-50'
              : 'text-gray-500'
          }   hover:cursor-pointer uppercase px-6 pt-4`}
        >
          Disponibilités{' '}
        </p>

        <p
          onClick={() => setSection('entretien')}
          className={`${
            section === 'entretien'
              ? 'font-semibold text-purple-500 border-l rounded-tl-lg rounded-tr-lg text-center border-t border-r w-1/4 bg-purple-50'
              : 'text-gray-500'
          }   hover:cursor-pointer uppercase px-6 pt-4`}
        >
          Entretien{' '}
        </p>

        <p
          onClick={() => {
            setSection('comment')
            console.log('doddodododo')
          }}
          className={`${
            section === 'comment'
              ? 'font-semibold text-yellow-500 border-l rounded-tl-lg rounded-tr-lg text-center border-t border-r w-1/4 bg-yellow-50'
              : 'text-gray-500'
          }   hover:cursor-pointer uppercase px-6 pt-4`}
        >
          Commentaires{' '}
        </p>

        <p
          onClick={() => setSection('contrat')}
          className={`${
            section === 'contrat'
              ? 'font-semibold text-ultramarine-500 border-l rounded-tl-lg rounded-tr-lg text-center border-t border-r w-1/4 bg-ultramarine-50'
              : 'text-gray-500'
          }   hover:cursor-pointer uppercase px-6 pt-4`}
        >
          Contrat{' '}
        </p>
      </div>

      {section === 'contrat' && (
        <CandidatContract
          user={user}
          setUser={setUser}
          setCandidates={setCandidates}
          candidates={candidates}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}

      {section === 'general' && (
        <CandidatInfo
          user={user}
          setUser={setUser}
          setCandidates={setCandidates}
          candidates={candidates}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}

      {section === 'document' && <div>{<CandidatDocuments user={user} />}</div>}

      {section === 'dispo' && (
        <div>
          <CandidatDispoTable
            user={user}
            setUser={setUser}
            candidates={candidates}
            setCandidates={setCandidates}
          />
        </div>
      )}

      {section === 'comment' && (
        <div>
          <CandidateComment
            setCandidates={setCandidates}
            candidates={candidates}
            candidate={user}
          />
        </div>
      )}

      {section === 'entretien' && (
        <div>
          <Entretien candidate={user} />
        </div>
      )}
    </div>
  )
}
