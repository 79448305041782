import { Dialog } from '@headlessui/react'
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance'
import AxiosInstance from '../../axios/axiosInstance'
import avatar from '../../assets/img/avatar.jpg'
import maladie from '../../assets/img/maladie.png'
import saisie from '../../assets/img/saisie.png'

import Compressor from 'compressorjs'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckIcon from '@mui/icons-material/Check'
import { Icon } from '@iconify/react'
import CandidatModalDetails from './CandidatModalDetails'
import { useEffect, useRef, useState } from 'react'
import {
  Button,
  DatePicker,
  Input,
  Modal,
  Select,
  TimePicker,
  message,
} from 'antd'
import sms from '../../assets/img/sms.png'
import gmail from '../../assets/img/gmail.png'
import euro from '../../assets/img/euro.png'
import controleref from '../../assets/img/controleref.png'
import rdv from '../../assets/img/rdv.png'
import jo from '../../assets/img/jo.png'
import contract from '../../assets/img/contract.png'
import { useNavigate } from 'react-router-dom'
import qualifications from '../../qualifications/qualifications'
import CreateContract from '../CreateContract/CreateContract'
import CreateFacture from '../CreateFacture/CreateFacture'
import AjoutArret from '../ArretMaladie/AjoutArret'
import { DeleteForever } from '@mui/icons-material'
import AjoutSaisie from '../SaisieArret/AjoutSaisie'

import PassJeux from './PassJeux/PassJeux'
import EnvoiFreelance from './EnvoiFreelance/EnvoiFreelance'
const CandidatModal = ({
  isOpen,
  setIsOpen,
  selectedUser,
  setCandidates,
  setSelectedUser,
  candidates,
}) => {
  const inputFileRef = useRef(null)
  const [userImg, setUserImg] = useState('')
  const [editName, setEditName] = useState(false)
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [acompteAmount, setAcompteAmount] = useState('')
  const [acompteModal, setAcompteModal] = useState(false)
  const [isControleReference, setIsControleReference] = useState(false)
  const [isRdv, setIsRdv] = useState(false)
  const [isAddArret, setIsAddArret] = useState(false)
  const [isAddSaisie, setIsAddSaisie] = useState(false)
  const [isCreateContract, setIsCreateContract] = useState(false)
  const [isCreateFacture, setIsCreateFacture] = useState(false)
  const [clients, setClients] = useState([])
  const [controleReference, setControleReference] = useState({})
  const [selectedClient, setSelectedClient] = useState(null)
  const [selectedClients, setSelectedClients] = useState([])
  const [clientContacts, setClientContacts] = useState([])
  const [clientAddresses, setClientAddresses] = useState([])
  const [selectedRdvAddress, setSelectedRdvAddress] = useState('')
  const [selectedRdvContact, setSelectedRdvContact] = useState(null)
  const [selectedRdvDate, setSelectedRdvDate] = useState(null)
  const [selectedRdvHour, setSelectedRdvHour] = useState(null)
  const [selectedPoste, setSelectedPoste] = useState('')
  const [timer, setTimer] = useState(null)
  const [isPassJeux, setIsPassJeux] = useState(false)
  const [isEnvoiFreelance, setIsEnvoiFreelance] = useState(false)

  const navigate = useNavigate()

  const qualificationsArray = qualifications.map((qualification) => {
    return {
      value: qualification,
      label: qualification,
      key: qualification,
    }
  })

  useEffect(() => {
    if (selectedUser) {
      setControleReference({ tempworkerCandidate: selectedUser._id })
      AxiosInstance.get(`tempworker-candidate/${selectedUser._id}/image`)
        .then((res) => {
          setUserImg(res.data.image)
        })
        .catch((e) => console.log(e))
    }
    setFirstname(selectedUser?.firstname)
    setLastname(selectedUser?.lastname)
  }, [selectedUser])

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day} ${month} ${year}`
  }

  const updateName = () => {
    console.log(firstname, lastname)
    AxiosInstance.post(`tempworker-candidate/${selectedUser._id}/update-name`, {
      firstname,
      lastname,
    })
      .then((res) => {
        message.success('Modification enregistréee ! 😏')
        setEditName(false)
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  const deleteImage = () => {
    AxiosInstance.delete(`tempworker-candidate/${selectedUser._id}/image`)
      .then((res) => {
        console.log(res.data)
        setUserImg('')
        message.success('Photo suprimée ! 😏')
      })
      .catch((error) => {
        console.error(error)
        message.success("Une erreur s'est produite ")
      })
  }

  const handleClick = () => {
    inputFileRef.current.click()
  }

  const askAcompte = () => {
    if (!selectedUser.pld) {
      message.error("Le candidat n'a pas de numero pld")
      return
    }

    if (!acompteAmount.length) {
      message.error('Ajouter un montant')
      return
    }

    AxiosInstance.post('acompte/create', {
      candidateId: selectedUser._id,
      amount: acompteAmount,
    })
      .then((res) => {
        setAcompteModal(false)
        message.success('Acompte crée')
      })
      .catch((e) => {
        message.error("Une erreur s'est produite !")
      })
  }

  const selectClient = (client) => {
    setSelectedClient(client)
    const addresses = [
      `${client.address}, ${client.postCode}, ${client.town}`,
      `${client.addressLivraison}, ${client.postCodeLivraison}, ${client.townLivraison}`,
      `${client.addressFacturation}, ${client.postCodeFacturation}, ${client.townFacturation}`,
    ]
    setClientAddresses(addresses)
    const contacts = client.contacts
    console.log(contacts)
    setClientContacts(contacts)
  }

  const selectContact = (contact) => {
    if (contact) setSelectedRdvContact(JSON.parse(contact))
  }

  const selectAddress = (address) => {
    setSelectedRdvAddress(address)
  }

  const createControleReference = () => {
    AxiosInstance.post('controle-reference', controleReference)
      .then((res) => {
        message.success('Controle de reference créé !')
        console.log(res.data)
        setIsControleReference(false)
      })
      .catch((e) => {
        message.error('Veuillez remplir tous les champs !')
        console.log(e)
      })
  }

  const resetRdvCreation = () => {
    setSelectedClient(null)
    setSelectedRdvAddress('')
    setSelectedRdvContact(null)
    setSelectedRdvDate(null)
    setSelectedRdvHour(null)
  }

  const activateCandidate = () => {
    const user = {
      firstname: selectedUser.firstname,
      lastname: selectedUser.lastname,
      email: selectedUser.email,
      candidate: selectedUser._id,
    }
    AxiosInstance.post('auth/activate-user', user)
      .then(() => {
        message.success("Le candidat a reçu un lien d'activation !")
      })
      .catch((e) => {
        console.log(e)
        message.error("Une erreur s'est produite !")
      })
  }

  const createTask = () => {
    const hour =
      selectedRdvHour.getHours() < 10
        ? '0' + selectedRdvHour.getHours()
        : selectedRdvHour.getHours()
    const minutes =
      selectedRdvHour.getMinutes() < 10
        ? '0' + selectedRdvHour.getMinutes()
        : selectedRdvHour.getMinutes()

    console.log(selectedPoste)

    AxiosInstance.post('entretien', {
      client: selectedClient._id,
      type: 'RDV',
      candidate: selectedUser._id,
      contact: selectedRdvContact,
      date: selectedRdvDate,
      hour: hour + ':' + minutes,
      place: selectedRdvAddress,
      poste: selectedPoste,
    })
      .then((res) => {
        resetRdvCreation()
        setIsRdv(false)
        message.success('RDV créé! ')
      })
      .catch((e) => {
        message.error("Une erreur s'est produite ! ")
        console.log(e)
      })
  }

  const search = (word) => {
    if (word.length === 0) {
      setSelectedClients([])
    }
    if (word.length < 3) {
      if (timer) clearTimeout(timer)
    }
    if (word.length >= 3) {
      if (timer) clearTimeout(timer)

      const timer2 = setTimeout(() => {
        AxiosInstance.post('client/find-by-text', { query: word })
          .then((res) => {
            setSelectedClients(res.data)
          })
          .catch((e) => console.log(e))
      }, 700)
      setTimer(timer2)
    }
  }

  const onChangePoste = (value) => {
    setSelectedPoste(value)
  }

  const handleFileChange = (event) => {
    event.preventDefault()
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = (e) => {
      setUserImg(e.target.result.split(',')[1])
    }
    reader.readAsDataURL(file)
    new Compressor(file, {
      quality: 0.6,
      width: 400,

      success(result) {
        const formData = new FormData()

        formData.append('file', result)

        AxiosInstance.put(
          `tempworker-candidate/${selectedUser._id}/image`,
          formData,
        )
          .then((res) => {
            console.log(res.data)
            setAcompteAmount('')
            message.success('Modifications enregistrées ! 😏')
          })
          .catch((error) => {
            console.error(error)
          })
      },
      error(err) {
        console.log(err.message)
      },
    })
  }

  return (
    <>
      {isPassJeux && (
        <PassJeux
          isPassJeux={isPassJeux}
          setIsPassJeux={setIsPassJeux}
          candidate={selectedUser}
        />
      )}

      {isEnvoiFreelance && (
        <EnvoiFreelance
          isEnvoiFreelance={isEnvoiFreelance}
          setIsEnvoiFreelance={setIsEnvoiFreelance}
          candidate={selectedUser}
        />
      )}
      {acompteModal && (
        <Modal
          open={acompteModal}
          footer={null}
          onCancel={() => {
            setAcompteModal(false)
            setAcompteAmount('')
          }}
        >
          <div className="flex flex-col space-y-2 mt-10">
            <p className="text-center text-2xl ">Demande d'acompte</p>
            <Input
              className="mt-10 rounded-md"
              value={acompteAmount}
              placeholder="Montant de l'acompte"
              onChange={(e) => setAcompteAmount(e.target.value)}
            />
            <Button onClick={askAcompte}>Valider</Button>
          </div>
        </Modal>
      )}

      {isControleReference && (
        <Modal
          open={isControleReference}
          footer={null}
          onCancel={() => {
            setIsControleReference(false)
          }}
        >
          <div className="flex flex-col space-y-1 mt-10">
            <Input
              onChange={(e) =>
                setControleReference({
                  ...controleReference,
                  companyName: e.target.value,
                })
              }
              className=" rounded-md"
              placeholder="Nom de la societé"
            />
            <Input
              onChange={(e) =>
                setControleReference({
                  ...controleReference,
                  companyAddress: e.target.value,
                })
              }
              className=" rounded-md"
              placeholder="Adresse de la societé"
            />
            <Input
              onChange={(e) =>
                setControleReference({
                  ...controleReference,
                  responsableName: e.target.value,
                })
              }
              className=" rounded-md"
              placeholder="Nom du responsable"
            />
            <Input
              onChange={(e) =>
                setControleReference({
                  ...controleReference,
                  responsablePhone: e.target.value,
                })
              }
              className=" rounded-md"
              placeholder="Numero du responsable"
            />
            <Input
              onChange={(e) =>
                setControleReference({
                  ...controleReference,
                  responsableEmail: e.target.value,
                })
              }
              className=" rounded-md"
              placeholder="Email du responsable"
            />
            <Select
              placeholder="Qualification"
              showSearch
              onChange={(e) =>
                setControleReference({ ...controleReference, qualification: e })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={qualificationsArray}
            />
            <button
              onClick={createControleReference}
              className="rounded-md bg-mountain-500 hover:bg-mountain-600 p-2 text-base uppercase font-semibold  text-white   "
            >
              Enregistrer
            </button>
          </div>
        </Modal>
      )}

      {isRdv && (
        <Modal
          open={isRdv}
          footer={null}
          onCancel={() => {
            setIsRdv(false)
            setSelectedClient(null)
            setSelectedClients([])
          }}
        >
          <div className="flex flex-col space-y-6 mt-10">
            <Input
              className="outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
              placeholder="Client"
              onChange={(e) => search(e.target.value)}
            />
            {selectedClients.length > 0 && (
              <div className="flex flex-col space-y-1  rounded-md mt-4 p-2 max-h-[400px] scrollbar-thin scrollbar-thumb-purple-700 scrollbar-track-purple-300 overflow-y-scroll">
                {selectedClients.map((client, idx) => {
                  return (
                    <div
                      key={idx}
                      onClick={() => selectClient(client)}
                      className={`border px-4 py-1 rounded-md border-gray-300 pb-2 ${
                        selectedClient?._id === client._id
                          ? 'bg-purple-400'
                          : ''
                      }`}
                    >
                      <div className="flex items-start space-x-3 justify-between mx-2">
                        <div>
                          <p className="text-lg font-semibold capitalize">
                            {client.companyName}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}

            {selectedClient && (
              <select
                onChange={(e) => selectContact(e.target.value)}
                className=" border rounded-md text-black border-black"
              >
                <option value="" disabled selected>
                  Contact du client
                </option>
                {clientContacts.map((contact) => {
                  return (
                    <option value={JSON.stringify(contact)}>
                      {contact.firstname} {contact.lastname}{' '}
                    </option>
                  )
                })}
              </select>
            )}

            {selectedClient && (
              <select
                onChange={(e) => selectAddress(e.target.value)}
                className=" border rounded-md text-black border-black"
              >
                <option value="" disabled selected>
                  Adresse du client
                </option>
                {clientAddresses.map((address) => {
                  return <option value={address}>{address} </option>
                })}
              </select>
            )}

            <Select
              className="col-span-1 h-8 px-2 py-1 outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
              showSearch
              value={selectedPoste}
              placeholder="75"
              optionFilterProp="children"
              onChange={onChangePoste}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={qualificationsArray}
            />

            <DatePicker
              onChange={(date, dateString) => setSelectedRdvDate(date.$d)}
              className="text-black"
              placeholder="Date du rdv"
              format={'DD/MM/YYYY'}
            />

            <TimePicker
              onChange={(time, timeString) => setSelectedRdvHour(time.$d)}
              className="text-black"
              placeholder="Heure du rdv"
              format={'HH:mm'}
            />
            <button
              onClick={createTask}
              className="rounded-md bg-mountain-500 hover:bg-mountain-600 p-2 text-base uppercase font-semibold  text-white   "
            >
              Enregistrer
            </button>
          </div>
        </Modal>
      )}

      {isAddArret && (
        <AjoutArret
          isAddArret={isAddArret}
          setIsAddArret={setIsAddArret}
          candidate={selectedUser}
        />
      )}

      {isAddSaisie && (
        <AjoutSaisie
          isAddSaisie={isAddSaisie}
          setIsAddSaisie={setIsAddSaisie}
          candidate={selectedUser}
        />
      )}

      {isCreateContract && (
        <CreateContract
          candidateId={selectedUser._id}
          isCreateContract={isCreateContract}
          setIsCreateContract={setIsCreateContract}
        />
      )}

      <div className="fixed inset-0 z-10 overflow-y-scroll scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100 flex items-center justify-center">
        <div className="flex w-full min-h-full bg-[rgba(63,67,66,0.39)] items-center justify-center p-4 sm:items-center sm:p-0">
          <div className="relative transform h-[95vh] w-full nav:w-4/5 mx-auto rounded-lg">
            <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse rounded-t-lg ">
              <Icon
                icon={'ic:round-close'}
                width={30}
                onClick={() => setIsOpen(false)}
                className="hover:cursor-pointer"
              />
            </div>
            <div className="bg-gray-50 flex w-full h-[90vh] overflow-scroll lg:px-12 rounded-b-lg justify-between items-start">
              <div className="flex  w-full justify-between space-x-10 mx-auto">
                <div className="flex flex-col items-center w-1/4 mb-16">
                  <div className="relative mb-4">
                    <img
                      src={
                        userImg ? `data:image/png;base64,${userImg}` : avatar
                      }
                      className="w-32 h-32 rounded-full"
                    />

                    <p className="absolute bottom-0 left-0">
                      <DeleteForever
                        onClick={deleteImage}
                        className="text-black text-3xl cursor-pointer"
                      />
                    </p>

                    <p className="absolute bottom-0 right-0">
                      <CameraEnhanceIcon
                        onClick={handleClick}
                        className="text-black text-3xl cursor-pointer"
                      />
                    </p>
                    <input
                      type="file"
                      onChange={(e) => handleFileChange(e)}
                      className="hidden"
                      ref={inputFileRef}
                    />
                  </div>
                  <div className="w-full text-center ">
                    <div className="flex items-center justify-center">
                      {!editName && (
                        <div className="flex items-center justify-center space-x-2">
                          <p className="text-2xl font-semibold uppercase">
                            {lastname + ' ' + firstname}
                          </p>
                        </div>
                      )}

                      {editName && (
                        <div className="flex max-w-1/4 items-center justify-center space-x-2">
                          <p className="font-light uppercase flex flex-col items-center space-x-2">
                            <input
                              className="grow max-h-12 text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 placeholder-gray-400 uppercase"
                              value={lastname}
                              onChange={(e) => setLastname(e.target.value)}
                            />
                            <input
                              className="grow max-h-12 text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 placeholder-gray-400"
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                            />
                            <button onClick={updateName}>
                              <CheckIcon className="rounded-md text-white text-4xl bg-mountain-500 border border-gray-400" />
                            </button>
                          </p>
                        </div>
                      )}
                      <EditOutlinedIcon
                        onClick={() => setEditName(!editName)}
                        className="text-3xl"
                      />
                    </div>

                    <p className="mt-1 text-sm">
                      Créé le{' '}
                      {new Date(selectedUser?.createdAt).toLocaleDateString(
                        'fr-FR',
                      )}{' '}
                      par{' '}
                      {`${selectedUser?.user?.firstname} ${selectedUser?.user?.lastname}`}
                    </p>

                    {selectedUser.isActivated && (
                      <p className="mt-1 text-sm">
                        Activé le{' '}
                        {new Date(
                          selectedUser?.activatedDate,
                        ).toLocaleDateString('fr-FR')}
                      </p>
                    )}
                    {selectedUser?.status === 'Disponible' && (
                      <p className="text-white rounded-md px-2 py-1 w-fit mx-auto mt-4 bg-green-600 font-semibold">
                        {selectedUser?.status}
                      </p>
                    )}

                    {selectedUser?.status === 'Indisponible' && (
                      <p className="text-white rounded-md px-2 py-1 w-fit mx-auto mt-4 bg-orange-400 font-semibold">
                        {selectedUser?.status}
                      </p>
                    )}

                    {selectedUser?.status === 'Bloqué' && (
                      <p className="text-white rounded-md px-2 py-1 w-fit mx-auto mt-4 bg-black font-semibold">
                        {selectedUser?.status}
                      </p>
                    )}
                  </div>
                  <div>
                    {selectedUser?.isRQTH && (
                      <p className="text-white rounded-md px-2 py-1 w-fit mx-auto mt-4 bg-black font-semibold">
                        RQTH
                      </p>
                    )}
                  </div>

                  <div className="flex items-center space-x-6 justify-center mt-8">
                    <img src={sms} className="w-8" />
                    <img src={gmail} className="w-8" />
                  </div>

                  {!selectedUser.isActivated && (
                    <div>
                      <p
                        onClick={() => activateCandidate()}
                        className="mt-24 w-56 text-center hover:cursor-pointer bg-purple-300 rounded-md p-2 font-semibold text-white flex items-center justify-center space-x-2"
                      >
                        <span>Activer le candidat</span>
                      </p>
                    </div>
                  )}

                  <div>
                    <p
                      onClick={() => setAcompteModal(true)}
                      className="mt-6 w-56 text-center hover:cursor-pointer bg-green-300 rounded-md p-2 font-semibold text-white flex items-center space-x-2"
                    >
                      <img src={euro} className="w-6" />
                      <span>Faire un acompte</span>
                    </p>
                  </div>

                  <div>
                    <p
                      onClick={() => setIsControleReference(true)}
                      className="mt-6 w-56 text-center hover:cursor-pointer bg-pink-300 font-semibold rounded-md p-2 text-white flex items-center space-x-2"
                    >
                      <img src={controleref} className="w-6" />
                      <span>Controle de reference</span>
                    </p>
                  </div>

                  <div>
                    <p
                      onClick={() => setIsRdv(true)}
                      className="mt-6 w-56 text-center hover:cursor-pointer bg-blue-300 font-semibold rounded-md p-2 text-white flex items-center space-x-2"
                    >
                      <img src={rdv} className="w-6" />
                      <span>RDV entretien</span>
                    </p>
                  </div>

                  {selectedUser.pld && (
                    <div>
                      <p
                        onClick={() => setIsPassJeux(true)}
                        className="mt-6 w-56 text-center hover:cursor-pointer  bg-ultramarine-900 font-semibold rounded-md p-2 text-white flex items-center space-x-2"
                      >
                        <img src={jo} className="w-6" />
                        <span>Pass Jeux</span>
                      </p>
                    </div>
                  )}

                  <div>
                    <p
                      onClick={() => setIsEnvoiFreelance(true)}
                      className="mt-6 w-56 text-center hover:cursor-pointer  bg-green-900 font-semibold rounded-md p-2 text-white flex items-center space-x-2"
                    >
                      <img src={rdv} className="w-6" />
                      <span>Envoyer à freelance</span>
                    </p>
                  </div>

                  <div>
                    <p
                      onClick={() => setIsAddArret(true)}
                      className="mt-6 w-56 text-center hover:cursor-pointer bg-red-300 font-semibold rounded-md p-2 text-white flex items-center space-x-2"
                    >
                      <img src={maladie} className="w-6" />
                      <span>Ajouter un arret</span>
                    </p>
                  </div>

                  <div>
                    <p
                      onClick={() => setIsAddArret(true)}
                      className="mt-6 w-56 text-center hover:cursor-pointer bg-red-300 font-semibold rounded-md p-2 text-white flex items-center space-x-2"
                    >
                      <img src={maladie} className="w-6" />
                      <span>Nouvelle candidature</span>
                    </p>
                  </div>

                  <div>
                    <p
                      onClick={() => setIsAddSaisie(true)}
                      className="mt-6 w-56 text-center hover:cursor-pointer bg-yellow-300 font-semibold rounded-md p-2 text-white flex items-center space-x-2"
                    >
                      <img src={saisie} className="w-6" />
                      <span>Ajouter une saisie administrative</span>
                    </p>
                  </div>

                  {/*  {selectedUser?.pld && (
                    <div>
                      <p
                        onClick={() => setIsCreateContract(true)}
                        className="mt-6 w-56 text-center hover:cursor-pointer bg-yellow-200 font-semibold rounded-md p-2 text-white flex items-center space-x-2"
                      >
                        <img src={contract} className="w-6" />
                        <span>Creer un contrat</span>
                      </p>
                    </div>
                  )} */}

                  <div>
                    <p
                      onClick={() => setIsCreateFacture(true)}
                      className="mt-6 w-56 text-center hover:cursor-pointer bg-purple-200 font-semibold rounded-md p-2 text-white flex items-center space-x-2"
                    >
                      <img src={contract} className="w-6" />
                      <span>Creer une vacation/placement</span>
                    </p>
                  </div>
                </div>
                <div className="w-3/4">
                  <CandidatModalDetails
                    user={selectedUser}
                    setCandidates={setCandidates}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    setUser={setSelectedUser}
                    candidates={candidates}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CandidatModal
